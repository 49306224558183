<template>
  <div class="page">
    <!-- tab -->
    <div class="tabBar">
      <div
        :class="['tab-item', { active: curIndex == index }]"
        @click="selectTabItem(item, index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.name }}
        <div class="line" v-if="curIndex == index"></div>
      </div>
    </div>
    <!-- 下沉党员 -->
    <div class="sinkPartyMember" v-if="curIndex == 0">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="item" v-for="(item, index) in listData" :key="index">
          <div class="item-l">
            <img :src="item.avatarUrl" alt="" />
          </div>
          <div class="item-r">
            <div class="name">{{ item.name }}</div>
            <div class="place">{{ item.belongBranch }}</div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 下沉动态 -->
    <div class="sinkDynamic" v-if="curIndex == 1">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toRouteTitle(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-l">
            <div class="name">{{ item.topicTitle || "" }}</div>
            <div class="item-l-b">
              <div class="time">{{ item.createTime }}</div>
              <div class="sum">
                <img src="./img/lookIcon.png" />
                <div class="num">{{ item.readCount }}</div>
              </div>
            </div>
          </div>
          <div class="item-r">
            <img :src="item.topicPicture" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getListUrl, getTopicListURL } from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "buildTeam",
  data() {
    return {
      curIndex: 0,
      tabList: [
        {
          name: "下沉党员",
        },
        {
          name: "下沉动态",
        },
      ],
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    onLoad() {
      this.getList();
    },
    selectTabItem(item, index) {
      this.curIndex = index;
      this.finished = true;
      this.listData = [];
      this.requestData.curPage = 1;
      this.requestData.pageSize = 10;
      this.onLoad();
    },
    getList() {
      this.listData = [];
      if (this.curIndex == 0) {
        let params = {
          curPage: this.requestData.curPage,
          pageSize: this.requestData.pageSize,
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
        };
        this.$axios.get(`${getListUrl}`, { params }).then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
      } else {
        let params = {
          curPage: this.requestData.curPage,
          pageSize: this.requestData.pageSize,
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          sortField: "NEW",
          topicType: "29",
        };
        this.$axios.get(`${getTopicListURL}`, { params }).then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  .sinkDynamic {
    .item {
      width: 100%;
      height: 186px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      display: flex;
      .item-l {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        padding: 26px 30px 0 30px;
        position: relative;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .item-l-b {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4b4b4;
          position: absolute;
          box-sizing: border-box;
          bottom: 28px;
          left: 0;
          padding-left: 30px;
          .time {
            white-space: nowrap;
          }
          .sum {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              display: block;
              width: 28px;
              height: 26px;
            }
          }
        }
      }
      .item-r {
        padding: 16px 30px 20px 0;
        box-sizing: border-box;
        width: 256px;
        height: 100%;
        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .sinkPartyMember {
    width: 100%;
    box-sizing: border-box;
    padding: 0 42px;
    .item {
      width: 100%;
      height: 100px;
      display: flex;
      margin-bottom: 38px;
      .item-l {
        width: 100px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      .item-r {
        flex: 1;
        padding: 10px 0 0 22px;
        box-sizing: border-box;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 6px;
        }
        .place {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .tabBar {
    height: 120px;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 30px;
    box-sizing: border-box;
    .tab-item {
      flex: 1;
      width: 100%;
      height: 120px;
      text-align: center;
      line-height: 120px;
      position: relative;
      .line {
        width: 34px;
        height: 6px;
        background: #fe9514;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 25px;
      }
    }
    .active {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fe9514;
    }
  }
}
</style>